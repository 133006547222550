<template>
	<div>
	 <h2>{{ $t("tiers.documents") }}</h2>

        <div class="box">
            <CustomTable
				ref="table"
                id_table="media"
                :items="documents"
                :busy="table_busy"
                primaryKey="media_id"
                :hide_if_empty="true"
            />
        </div>
        <ModalAddDocument 
			ref="modalAddDocument"
			:refresh_table.sync="refresh_table"
			:media="selected"
			type="tiers"
			:fk="tiers_id"
		/>
		<ModalViewDocument
			ref="modalViewDocument"
			:media="selected"
		/>
    </div>
</template>

<script type="text/javascript">
    import Media from "@/mixins/CloudMedia.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'TiersDocument',
		mixins: [Media, Navigation],
		props: ['tiers_id'],
		data () {
			return {
				table_busy: false,
				documents: [],
				selected: null,
				refresh_table: false,
				events_tab: {
					'TableAction::goToAddMedia': () => {
                    	this.selected = null
						this.$refs.modalAddDocument.openModal()
                    },
					'TableAction::goToEditMedia': (media) => {
                    	this.selected = media
                    	this.$refs.modalAddDocument.openModal()
                    },
                    'TableAction::goToViewMedia' : (media) => {
                    	this.selected = media
						this.$refs.modalViewDocument.openModal()
                    },
					'TableAction::goToDeleteMedia': this.deleteDocument
				},
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.table_busy = true
				this.documents = await this.getMediaByFK('tiers', this.tiers_id)
				
				if(this.$refs.table) {
					this.$refs.table.refreshTable()
				}
				this.table_busy = false
			},

			async deleteDocument(medias) {
				let tab_promises = []
				for(let i in medias) {
					tab_promises.push(this.deleteMedia(medias[i].media_id))
				}

				Promise.all(tab_promises)
				.then(() => {
					this.init_component()
					this.$refs.table.resetCachedItems()
					this.successToast("toast.info_save_succes")
				})
				.catch(() => {
					this.failureToast()
				})
			}
		},

		watch: {
			refresh_table() {
				this.init_component()
			}
		},

		components: {
			CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			ModalAddDocument: () => import('@/components/Media/ModalAddDocument'),
			ModalViewDocument: () => import('@/components/Media/ModalViewDocument')
		}
	}

</script>